import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface FormControls {
  address?: string;
  country?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormControlService {
  controlValueSource!: BehaviorSubject<FormControls>;
  controlValue!: Observable<FormControls>;

  constructor() {
    this.controlValueSource = new BehaviorSubject<FormControls>({});
    this.controlValue = this.controlValueSource.asObservable();
  }

  change(value: FormControls) {
    this.controlValueSource.next(value);
  }
}
