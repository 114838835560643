import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FormControlService } from '../../services/form-control.service';
import { DropdownModule } from 'primeng/dropdown';

interface Country {
  name: string;
  value: string;
}

@Component({
    selector: 'country-select',
    templateUrl: './country-select.component.html',
    styleUrls: ['./country-select.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [DropdownModule, FormsModule]
})
export class CountrySelectComponent implements OnInit, OnDestroy {
  countries!: Country[];
  selectedCountry!: string | undefined;
  subscription!: Subscription;
  @Output() countryValueChanged = new EventEmitter<boolean>();

  constructor(
    private formControlService: FormControlService
  ) {
    this.countries = [
      { name: 'Hrvatska', value: 'Croatia' },
      { name: 'Bosna i Hercegovina', value: 'BH' },
      { name: 'Srbija', value: 'Serbia' }
    ];
  }

  ngOnInit(): void {
    this.subscription = this.formControlService.controlValue.subscribe(
      value => {
        this.selectedCountry = value.country;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
