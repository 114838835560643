import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Dropdown, DropdownModule } from 'primeng/dropdown';

import { ServerError } from '@models/server-error';
import { FormControlService } from '@services/form-control.service';
import { FindLocationService } from '@services/find-location.service';
import { ErrorHandlerService } from '@services/error-handler.service';

export interface Location {
  name: string;
}

@Component({
  selector: 'address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [DropdownModule, FormsModule, AsyncPipe],
})
export class AddressSearchComponent implements OnInit, OnDestroy {
  @ViewChild(Dropdown) dropdown!: Dropdown;
  @Output() addressValueChange = new EventEmitter<boolean>();
  locations = new BehaviorSubject<Location[]>([]);
  selectedAddress!: string | undefined;
  subscription!: Subscription;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private findLocationService: FindLocationService,
    private formControlService: FormControlService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.formControlService.controlValue.subscribe(
      (value) => {
        this.selectedAddress = value.address;
      }
    );
    if (this.selectedAddress !== undefined) {
      this.locations.next([{ name: this.selectedAddress }]);
    }
  }

  async findLocation(address: string) {
    try {
      const location = await this.findLocationService.findLocation(address);

      let locations: Location[] = [];
      location.features.forEach((location) => {
        locations.push({
          name: location.place_name,
        });
      });
      this.locations.next(locations);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  setNewAddress() {
    if (!this.selectedAddress) {
      this.selectedAddress = this.dropdown.filterValue || '';
      this.locations.next([{ name: this.selectedAddress }]);
      this.onChangeAddressEmit();
    }
  }

  onChangeAddressEmit() {
    this.addressValueChange.emit(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
