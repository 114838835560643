<p-dropdown
  styleClass="country"
  [options]="countries"
  optionLabel="name"
  optionValue="value"
  [(ngModel)]="selectedCountry"
  name="country"
  #country="ngModel"
  placeholder="Država organizacije"
  [required]="true"
  (onChange)="this.countryValueChanged.emit(true)"
>
</p-dropdown>
