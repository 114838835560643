<p-dropdown
  styleClass="address"
  [options]="(locations | async) || []"
  optionLabel="name"
  optionValue="name"
  [filter]="true"
  filterBy="name"
  emptyFilterMessage="Nije pronađena adresa"
  emptyMessage="Pretraži adrese"
  [(ngModel)]="selectedAddress"
  name="address"
  #address="ngModel"
  placeholder="Adresa organizacije"
  (onFilter)="findLocation($event.filter)"
  (onChange)="onChangeAddressEmit()"
  (onHide)="setNewAddress()"
>
</p-dropdown>
