import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  imgUrl!: Observable<boolean>;
  imgUrlSource!: BehaviorSubject<boolean>;
  user!: Observable<boolean>;
  userSource!: BehaviorSubject<boolean>;
  private storageService: StorageService;

  constructor(
    private handler: HttpBackend,
    private httpNoInterceptor: HttpClient
  ) {
    this.storageService = new StorageService();
    this.httpNoInterceptor = new HttpClient(this.handler);
    this.imgUrlSource = new BehaviorSubject<boolean>(false);
    this.imgUrl = this.imgUrlSource.asObservable();
    this.userSource = new BehaviorSubject<boolean>(false);
    this.user = this.userSource.asObservable();
  }

  getResource(link: string): Observable<Blob> {
    return this.httpNoInterceptor.get<Blob>(link || '', {
      responseType: 'blob' as 'json',
    });
  }

  getImage(): string {
    const image = this.storageService.get('profileImg').toJSON().value;
    let profileImg = 'assets/img/avatar.png';

    if (image) {
      profileImg = image;
    }

    return profileImg;
  }

  setImage(profilePhoto: string) {
    this.getResource(profilePhoto).subscribe({
      next: (resLink) => {
        let reader = new FileReader();
        reader.readAsDataURL(resLink);
        reader.onload = (img) => {
          const imageUrl =
            img.target?.result?.toString() || 'assets/img/avatar.png';
          this.storageService.setValue(imageUrl);
          this.storageService.toString().store('profileImg');
          this.imgUrlSource.next(true);
        };
      },
    });
    const imageUrl = 'assets/img/avatar.png';
    this.storageService.setValue(imageUrl);
    this.storageService.toString().store('profileImg');
    this.imgUrlSource.next(true);
  }

  getName(): string {
    const user = this.storageService.get('user').toJSON().value;
    let name = '';

    if (user) {
      name = user.basicInfo.firstName + ' ' + user.basicInfo.lastName;
    }

    return name;
  }

  setVerification(verified: boolean) {
    const user = this.storageService.get('user').toJSON().value;

    if (user) {
      user.basicInfo.verified = verified;
      this.storageService.setValue(user);
      this.storageService.toString().store('user');
    }
  }

  getVerification(): boolean {
    const user = this.storageService.get('user').toJSON().value;
    let verified = false;

    if (user) {
      verified = user.basicInfo.verified;
    }

    return verified;
  }
}
