import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Location } from '../interfaces/location';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root',
})
export class FindLocationService {
  httpClientService: HttpClientService;

  constructor() {
    this.httpClientService = new HttpClientService();
  }

  /**
   * Finds the location for the given address using the Mapbox Geocoding API.
   *
   * @param address - The address to search for.
   * @returns A Promise that resolves to the location information.
   */
  findLocation(address: string): Promise<Location> {
    return this.httpClientService
      .method('GET')
      .baseUrl('https://api.mapbox.com/geocoding/v5/mapbox.places')
      .route(`/${address}.json`)
      .params({
        bbox: environment.BBOX,
        proximity: environment.PROXIMITY,
        types: environment.TYPES,
        language: 'bs',
        autocomplete: 'true',
        fuzzyMatch: 'true',
        access_token: environment.ACCESS_TOKEN_MAPBOX,
      })
      .response<Location>();
  }
}
