import { Injectable } from '@angular/core';

import { NewTenant, Tenant, TenantDetails, Tenants } from './tenants.interface';
import { Message } from 'src/app/shared/interfaces/alert';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  private httpClientService: HttpClientService;

  constructor() {
    this.httpClientService = new HttpClientService();
  }

  /* Tenants */

  /**
   *  Get active tenant
   *
   * @returns Promise of type Tenants
   */
  tenant(): Promise<Tenants> {
    return this.httpClientService
      .method('GET')
      .route('/tenant')
      .withAuthToken()
      .response<Tenants>();
  }

  /**
   *  Select tenant
   *
   * @param id Tenant id
   * @returns Promise of type Tenant
   */
  selectTenant(id: number): Promise<Tenant> {
    return this.httpClientService
      .method('POST')
      .route('/tenant/select')
      .body({ id })
      .withAuthToken()
      .response<Tenant>();
  }

  /**
   *  Store tenant
   *
   * @param tenant Tenant to create
   * @returns Promise of type TenantDetails
   */
  store(tenant: NewTenant): Promise<TenantDetails> {
    return this.httpClientService
      .method('POST')
      .route('/tenant/store')
      .body(tenant)
      .withAuthToken()
      .response<TenantDetails>();
  }

  /**
   *  Send invitation for tenant
   *
   * @param tenant Array of tenant ids
   * @param email Email of user to be invited to tenant
   * @returns Promise of type Message
   */
  sendInvitation(
    tenant: Array<number>,
    email: Array<string>
  ): Promise<Message> {
    return this.httpClientService
      .method('POST')
      .route('/invitation/send')
      .body({ tenant, email })
      .withAuthToken()
      .response<Message>();
  }

  /**
   *  Accept invitation for tenant
   *
   * @param action Action to do on invitation
   * @param token Invitation token
   * @returns Promise of type Message
   */
  invitationAction(action: string, token: string): Promise<Message> {
    return this.httpClientService
      .method('GET')
      .route(`/invitation/${action}/${token}`)
      .withAuthToken()
      .response<Message>();
  }
}
